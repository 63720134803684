.container{
    width: 100%;
    height: 100vh;
    background-color: rgb(63, 154, 199);
}
.weather{
    width: 380px;
    height: 500px;
    /* padding: 30px; */
    border-radius: 10px;
    /* margin: 50px 50px; */
    background:linear-gradient(130deg, rgb(86,86,235),rgb(5,5,199));
    text-align: center;
    align-items: center;
    color: white;
    position: relative;
    top: 80px;
    left:550px;

}
.search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 10px;
    
}
.search input {
    border-radius: 30px;
    border: none;
    background-color: white;
    color: black;
    font-size: 18px;
    padding: 12px 25px;
    margin-right: 12px;
    margin-left: 20px;
}
.search button{
    border: none;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    cursor: pointer;
    margin-right: 40px;
}
.searchbtn {
    width: 35px;
   
}
.winfo{
    margin-top: 20px;
   
}
.winfo h1{
    font-size: 60px;
    font-weight: 400;
    margin-top: 5px;
}
.winfo h2{
    font-size: 35px;
    font-weight: 350;
    margin-top: -40px;
}

.img{
    
    width: 100px;
    height: 100px;
    border-radius: 50px;
}
.wind{
    width: 60px;
    height: 50px;
    color: white;
    margin-right: 5px;
}
.humidity{
    width: 70px;
    height: 50px;
    /* margin-right: 10px; */
}
.details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 50px;
}
.details .col{
    display: flex;
    align-items: center;
    text-align: left;
}
.error{
    color: rgb(243, 239, 239);
    text-align: left;
    margin-left: 50px;
}
